<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<!-- START HERE -->

							<article class="article-body" id="article-body" rel="image-enlarge">
								<p>
									To use {{ $store.state.brandInfo.displayName }} service in your Android device, open Google Play Store, search for "{{
										$store.state.brandInfo.appName
									}}" and install it.
								</p>
								<p>Please make sure you Install {{ $store.state.brandInfo.appName }} STB.</p>

								<p><br /></p>
								<p dir="ltr">To login type in the the following: <strong>(Link updated October 2021)</strong></p>
								<p>
									<a dir="ltr" :href="`${$store.state.brandInfo.tvLink}/api/list/EMAIL/PASSWORD/m3u8/livetv`"
										>{{ $store.state.brandInfo.tvLink }}/api/list/EMAIL/PASSWORD</a
									>
								</p>
								<p><br /></p>
								<p><br /></p>
								<p>Example: Bob's account info is:</p>
								<p>
									email:
									<a href="/cdn-cgi/l/email-protection#70121f12434330171d11191c5e131f1d"><span class="__cf_email__">bobemail@email.email</span></a>
								</p>
								<p>password: 123bb1</p>
								<p><br /></p>
								<p>The link will look:</p>
								<p>
									<a dir="ltr" :href="`${$store.state.brandInfo.tvLink}/api/list/EMAIL/PASSWORD/m3u8/livetv`"
										>{{ $store.state.brandInfo.tvLink }}/api/list/<span class="__cf_email__">bobemail@email.email</span>/123bb1</a
									>
								</p>
								<p><br /></p>
								<p><br /></p>
								<p>
									<img :src="getPic('STB.png')" style="width: auto" class="fr-fic fr-fil fr-dib" data-attachment="[object Object]" data-id="48017029522" />
								</p>
								<p><br /></p>
							</article>
							<!-- FINISH HERE -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';

export default {
	name: 'howDoIInstallOnAndroidBox',
	data() {
		return {
			title: this.$route.meta.title
		};
	},
	methods: {
		getPic(picSuffix) {
			try {
				const appName = _.lowerCase(this.$store.state.brandInfo.appName).replace(/ /g, '');
				if (!appName) {
					return;
				}

				return require(`@/assets/images/with-logo/${appName}${picSuffix}`);
			} catch (err) {
				console.error(err);
			}
		}
	}
};
</script>

<style scoped></style>
